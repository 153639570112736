import logo from './logo.svg';
import './App.css';

function App() {
  return (        
    <div className="App">
      <header className="App-header">
        <h1>Daily Dose Web3</h1>
        <a target="_blank" rel="noopener noreferrer" href="https://www.coindesk.com/">CoinDesk</a>
        <a target="_blank" rel="noopener noreferrer" href="https://cointelegraph.com/">Cointelegraph</a>
        <a target="_blank" rel="noopener noreferrer" href="https://techcrunch.com/tag/crypto/">Tech Crunch</a>
        <a target="_blank" rel="noopener noreferrer" href="https://decrypt.co/news">Decrypt</a>
        <a target="_blank" rel="noopener noreferrer" href="https://www.theblockresearch.com/">The Block</a>
        <a target="_blank" rel="noopener noreferrer" href="https://messari.io/research">Messari</a>
        <a target="_blank" rel="noopener noreferrer" href="https://members.delphidigital.io/home">Delphi Digital</a>
        <a target="_blank" rel="noopener noreferrer" href="https://dune.com/browse/dashboards">Dune</a>
        <h1>Metrics</h1>
        <a target="_blank" rel="noopener noreferrer" href="https://defillama.com/">DefiLlama</a>
        <a target="_blank" rel="noopener noreferrer" href="https://app.artemis.xyz/dashboard">Artemis</a>
        <a target="_blank" rel="noopener noreferrer" href="https://www.coingecko.com/">CoinGecko</a>
        <a target="_blank" rel="noopener noreferrer" href="https://dappradar.com/rankings">DappRadar</a>
        <a target="_blank" rel="noopener noreferrer" href="https://flipsidecrypto.xyz/">Flipside</a>
        <h1>NFTs</h1>
        <a target="_blank" rel="noopener noreferrer" href="https://opensea.io/rankings">OpenSea</a>
        <a target="_blank" rel="noopener noreferrer" href="https://blur.io/">Blur</a>
        <a target="_blank" rel="noopener noreferrer" href="https://magiceden.io/">Magic Eden</a>
        <p></p>
      </header>
    </div>
  );
}

export default App;
